import useToggle from "hooks/useToggle";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getColorVariableName, optimizedImage } from "utils/util";
import DesktopNavbarContentStyle from "./desktopNavbarContentStyle";

const Box = dynamic(() => import("@mui/material/Box"));
const SearchIcon = dynamic(() => import("@mui/icons-material/Search"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));
const LanguageSelector = dynamic(() =>
  import("components/localization/languageSelector/languageSelector")
);
const SearchBar = dynamic(() =>
  import("components/search/searchBar/searchBar")
);
const NavUserIcon = dynamic(() => import("../navUserIcon"));
const NavbarBrand = dynamic(() => import("../navbarBrand"));
const ContextHoverNavigation = dynamic(() =>
  import("./contextHoverNavigation/contextHoverNavigation")
);
const FullScreenHoverNavigation = dynamic(() =>
  import("./fullScreenHoverNavigation/fullScreenHoverNavigation")
);
const DesktopNavbarWithDropdowns = dynamic(() =>
  import("./singleElementDropdownNav/desktopNavbarWithDropdowns")
);

const DesktopNavbarContent = ({ navigation }) => {
  const [searchActive, toggleSearchActive] = useToggle(false);
  const { t: tPublic } = useTranslation("public");
  const renderNavigation = () => {
    switch (globalSettings.navigation.desktopNavigationType) {
      case "dropdowns":
        return <DesktopNavbarWithDropdowns navigation={navigation} />;
      case "hoverMenu":
        return <FullScreenHoverNavigation navigation={navigation} />;
      default:
        return <ContextHoverNavigation navigation={navigation} />;
    }
  };

  return (
    <>
      {!globalSettings.navigation.navLogoPositionRight ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            visibility: searchActive ? "hidden" : "visible",
          }}
        >
          <NavbarBrand
            // Not using navLogoWidth for better logoQuality.
            mlogo={
              navigation && navigation.mlogo
                ? optimizedImage(navigation.mlogo, true, 400)
                : null
            }
            url={navigation && navigation.logoUrl ? navigation.logoUrl : null}
            width={globalSettings?.navigation?.navLogoWidth}
            height={globalSettings?.navigation?.navLogoHeight}
          />
        </Box>
      ) : null}
      {globalSettings.languageswitch.visible &&
      !globalSettings.navigation.languageSelectorRight ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            visibility: searchActive ? "hidden" : "visible",
          }}
        >
          <LanguageSelector />
        </Box>
      ) : null}
      {!globalSettings.navigation.searchIconRight ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            visibility: searchActive ? "hidden" : "visible",
          }}
        >
          <IconButton
            aria-label={tPublic("search")}
            onClick={() => toggleSearchActive(true)}
            className="search-button-desktop"
          >
            <SearchIcon
              alt={tPublic("search")}
              fontSize="large"
              style={{
                fill: `var(${getColorVariableName(
                  globalSettings.search.searchIconInNavColor
                )})`,
              }}
            />
          </IconButton>
        </Box>
      ) : null}
      {searchActive ? (
        <SearchBar
          active={searchActive}
          onSubmit={() => toggleSearchActive(false)}
          toggleSearch={() => toggleSearchActive(false)}
        />
      ) : null}

      <Box
        className="navigation-content"
        sx={searchActive ? { visibility: "hidden" } : null}
      >
        {renderNavigation()}
      </Box>

      {globalSettings.navigation.searchIconRight ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            visibility: searchActive ? "hidden" : "visible",
          }}
        >
          <IconButton
            aria-label={tPublic("search")}
            onClick={() => toggleSearchActive(true)}
            className="search-button-desktop"
          >
            <SearchIcon
              alt={tPublic("search")}
              fontSize="large"
              style={{
                fill: `var(${getColorVariableName(
                  globalSettings.search.searchIconInNavColor
                )})`,
              }}
            />
          </IconButton>
        </Box>
      ) : null}
      {globalSettings.languageswitch.visible &&
      globalSettings.navigation.languageSelectorRight ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            visibility: searchActive ? "hidden" : "visible",
          }}
        >
          <LanguageSelector />
        </Box>
      ) : null}
      {globalSettings.navigation.navLogoPositionRight ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            visibility: searchActive ? "hidden" : "visible",
          }}
        >
          <NavbarBrand
            // Not using navLogoWidth for better logoQuality.
            mlogo={
              navigation && navigation.mlogo
                ? optimizedImage(navigation.mlogo, true, 400)
                : null
            }
            url={navigation && navigation.logoUrl ? navigation.logoUrl : null}
            width={globalSettings?.navigation?.navLogoWidth}
            height={globalSettings?.navigation?.navLogoHeight}
          />
        </Box>
      ) : null}
      {!searchActive ? (
        <NavUserIcon
          icon={globalSettings.navigation.userIcon}
          width={globalSettings.navigation.userIconWidthAndHeightDesktop || 40}
          height={globalSettings.navigation.userIconWidthAndHeightDesktop || 40}
        />
      ) : null}

      <DesktopNavbarContentStyle />
      <style jsx global>{`
        /* In some cases the navbar is missing the position: relative attribute. */
        #navbar {
          position: ${searchActive ? `relative !important` : `static`};
        }
      `}</style>
    </>
  );
};
export default DesktopNavbarContent;
