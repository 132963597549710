import { globalSettings } from "services/globalSettings/globalSettingsService";
export default function DesktopNavbarContentStyle() {
  let justifyContentWrapper = "center";
  let flexGrowContentBox = "1";
  let justifyContentBox = "space-evenly";

  switch (globalSettings.navigation.baseLayerJustifyContent) {
    case "start":
      justifyContentWrapper = "start";
      flexGrowContentBox = "0";
      justifyContentBox = "center";
      break;
    case "center":
      justifyContentWrapper = "center";
      flexGrowContentBox = "0";
      justifyContentBox = "center";
      break;
    case "end":
      justifyContentWrapper = "end";
      flexGrowContentBox = "0";
      justifyContentBox = "center";
      break;
    case "spaceEvenly":
      justifyContentWrapper = "center";
      flexGrowContentBox = "1";
      justifyContentBox = "space-evenly";
      break;
    case "spaceAround":
      justifyContentWrapper = "center";
      flexGrowContentBox = "1";
      justifyContentBox = "space-around";
      break;
    case "spaceBetween":
      justifyContentWrapper = "center";
      flexGrowContentBox = "1";
      justifyContentBox = "space-between";
      break;
    default:
      break;
  }

  return (
    <style jsx global>{`
      .navigation-desktop-container {
        display: none;
        @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
          display: ${globalSettings.navigation.alwaysUseMobileView
            ? "none"
            : "flex"};
        }
        @media screen and (hover: none) {
          display: none;
        }
        flex-grow: 1;
        flex-wrap: nowrap;
        position: unset;
        max-width: 100%;
        justify-content: ${globalSettings.navigation.centerNavigationWithImage
          ? "center"
          : "unset"};
        .navigation-brand {
          align-items: center;
        }
        .navigation-content {
          position: unset;
          display: flex;
          flex-grow: ${globalSettings.navigation.centerNavigationWithImage
            ? "0"
            : "1"};
          flex-wrap: wrap;
          justify-content: ${justifyContentWrapper};
          max-width: 100%;
          .navigation-content-box {
            position: unset;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            flex-grow: ${flexGrowContentBox};
            justify-content: ${justifyContentBox};
          }
        }
        .search-button-desktop {
          height: fit-content;
        }
        .search-bar {
          width: 80%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .navigation-dropdown-menu,
      .third-level-dropdown {
        .MuiList-root.MuiMenu-list {
          padding-top: 0;
          padding-bottom: 0;
          background-color: ${globalSettings.navigation
            .notBaseLayerBackgroundColor};
        }
      }
      .third-level-dropdown {
        .MuiMenuItem-root.not-base-level {
          .dropdown-link,
          .MuiTypography-root {
            font-weight: ${globalSettings.navigation.fontWeightLayer3 || 400};
          }
        }
      }
      // styling just for dropdowns
      .MuiMenuItem-root.not-base-level {
        padding: 0;
        background-color: ${globalSettings.navigation
          .notBaseLayerElementBackgroundColor};
        .dropdown-link,
        .MuiTypography-root {
          width: 100%;
          text-decoration: none;
          // padding: 0.8rem 1rem;
          padding-left: ${globalSettings.navigation.navbarElementPaddingX}px;
          padding-right: ${globalSettings.navigation.navbarElementPaddingX}px;
          padding-top: ${globalSettings.navigation.navbarElementPaddingY}px;
          padding-bottom: ${globalSettings.navigation.navbarElementPaddingY}px;
          font-size: ${globalSettings.navigation.fontSize}px;
          font-weight: ${globalSettings.navigation.fontWeightLayer2 || 400};
          color: ${globalSettings.navigation.notBaseLayerElementFontColor};
        }
        .MuiSvgIcon-root {
          color: ${globalSettings.navigation.notBaseLayerElementFontColor};
        }

        &:hover {
          background-color: ${globalSettings.navigation
            .hoverElementBackgroundColor} !important;
          .dropdown-link,
          .MuiTypography-root,
          .MuiSvgIcon-root {
            color: ${globalSettings.navigation
              .hoverElementFontColor} !important;
          }
        }
        &.active {
          background-color: ${globalSettings.navigation
            .activeElementBackgroundColor} !important;
          .dropdown-link,
          .MuiTypography-root,
          .MuiSvgIcon-root {
            color: ${globalSettings.navigation
              .activeElementFontColor} !important;
          }
        }
      }
    `}</style>
  );
}
